var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer", attrs: { id: "footer" } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "footer__content" }, [
        _c("div", { staticClass: "footer__top" }, [
          _c(
            "div",
            { staticClass: "footer__logo logo" },
            [
              _c(
                "nuxt-link",
                { attrs: { to: _vm.localePath("/") } },
                [
                  _c("nuxt-img", {
                    staticClass: "logo__img",
                    attrs: { src: "/seo/logo.svg", title: _vm.$config.appName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("nav", [
            _c("ul", { staticClass: "footer__menu-2 menu-2" }, [
              _c(
                "li",
                { staticClass: "menu-2__item" },
                [
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/about") } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common.about")) +
                        "\n              "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu-2__item" },
                [
                  _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/support") } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.support")) +
                          "\n              "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu-2__item" },
                [
                  _c(
                    "nuxt-link",
                    { attrs: { to: _vm.localePath("/policy") } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("common.policy")) +
                          "\n              "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu-2__item" },
                [
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/terms") } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common.terms")) +
                        "\n              "
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                { staticClass: "menu-2__item" },
                [
                  _c("nuxt-link", { attrs: { to: _vm.localePath("/faq") } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("common.faq")) +
                        "\n              "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "footer__social social" }, [
            _c("div", { staticClass: "social-icons" }, [
              _vm.contactsData.facebook !== undefined &&
              _vm.contactsData.facebook !== null &&
              _vm.contactsData.facebook.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-facebook",
                      attrs: {
                        href: _vm.contactsData.facebook,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-facebook" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contactsData.instagram !== undefined &&
              _vm.contactsData.instagram !== null &&
              _vm.contactsData.instagram.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-instagram",
                      attrs: {
                        href: _vm.contactsData.instagram,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-instagram" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contactsData.telegram !== undefined &&
              _vm.contactsData.telegram !== null &&
              _vm.contactsData.telegram.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-telegram",
                      attrs: {
                        href: _vm.contactsData.telegram,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-telegram" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contactsData.youtube !== undefined &&
              _vm.contactsData.youtube !== null &&
              _vm.contactsData.youtube.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-youtube",
                      attrs: {
                        href: _vm.contactsData.youtube,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-youtube" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contactsData.twitter !== undefined &&
              _vm.contactsData.twitter !== null &&
              _vm.contactsData.twitter.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-twitter",
                      attrs: {
                        href: _vm.contactsData.twitter,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-twitter" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.contactsData.vk !== undefined &&
              _vm.contactsData.vk !== null &&
              _vm.contactsData.vk.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "si-vk",
                      attrs: {
                        href: _vm.contactsData.vk,
                        rel: "nofollow noopener noreferrer",
                        target: "_blank",
                      },
                    },
                    [_c("i", { staticClass: "bx bxl-vk" })]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.emails !== null
              ? _c(
                  "div",
                  { staticClass: "emails" },
                  _vm._l(_vm.emails, function (email, ind) {
                    return _c("div", { key: ind, staticClass: "email-item" }, [
                      _c("i", { staticClass: "bx bx-envelope" }),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "social__link",
                          attrs: {
                            href: "mailto:" + email.toLowerCase(),
                            rel: "nofollow noopener noreferrer",
                            target: "_blank",
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(email) +
                              "\n              "
                          ),
                        ]
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "footer__bottom" }, [
          _c("div", { staticClass: "relations" }, [
            _c("p", { staticClass: "footer__rights" }, [
              _vm._v(
                "\n            © " +
                  _vm._s(_vm.currentYear()) +
                  " " +
                  _vm._s(_vm.$config.appName) +
                  ".\n            "
              ),
              _c("span", [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("common.rights")) +
                    "\n            "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer__app" }, [
      _c("div", { staticClass: "bg-AppStore" }),
      _vm._v(" "),
      _c("div", { staticClass: "bg-GooglePlay" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }