<template>
  <footer id="footer" class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__top">
          <div class="footer__logo logo">
            <nuxt-link :to="localePath('/')">
              <nuxt-img src="/seo/logo.svg" class="logo__img" :title="$config.appName" />
            </nuxt-link>
          </div>
          <nav>
            <ul class="footer__menu-2 menu-2">
              <li class="menu-2__item">
                <nuxt-link :to="localePath('/about')">
                  {{ $t('common.about') }}
                </nuxt-link>
              </li>
              <li class="menu-2__item">
                <nuxt-link :to="localePath('/support')">
                  {{ $t('common.support') }}
                </nuxt-link>
              </li>
              <li class="menu-2__item">
                <nuxt-link :to="localePath('/policy')">
                  {{ $t('common.policy') }}
                </nuxt-link>
              </li>
              <li class="menu-2__item">
                <nuxt-link :to="localePath('/terms')">
                  {{ $t('common.terms') }}
                </nuxt-link>
              </li>
              <li class="menu-2__item">
                <nuxt-link :to="localePath('/faq')">
                  {{ $t('common.faq') }}
                </nuxt-link>
              </li>
            </ul>
          </nav>
          <div class="footer__social social">
            <div class="social-icons">
              <a v-if="contactsData.facebook !== undefined && contactsData.facebook !== null && contactsData.facebook.length > 0" class="si-facebook" :href="contactsData.facebook" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-facebook"></i>
              </a>
              <a v-if="contactsData.instagram !== undefined && contactsData.instagram !== null && contactsData.instagram.length > 0" class="si-instagram" :href="contactsData.instagram" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-instagram"></i>
              </a>
              <a v-if="contactsData.telegram !== undefined && contactsData.telegram !== null && contactsData.telegram.length > 0" class="si-telegram" :href="contactsData.telegram" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-telegram"></i>
              </a>
              <a v-if="contactsData.youtube !== undefined && contactsData.youtube !== null && contactsData.youtube.length > 0" class="si-youtube" :href="contactsData.youtube" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-youtube"></i>
              </a>
              <a v-if="contactsData.twitter !== undefined && contactsData.twitter !== null && contactsData.twitter.length > 0" class="si-twitter" :href="contactsData.twitter" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-twitter"></i>
              </a>
              <a v-if="contactsData.vk !== undefined && contactsData.vk !== null && contactsData.vk.length > 0" class="si-vk" :href="contactsData.vk" rel="nofollow noopener noreferrer" target="_blank">
                <i class="bx bxl-vk"></i>
              </a>
            </div>
            <div v-if="emails !== null" class="emails">
              <div v-for="(email, ind) in emails" :key="ind" class="email-item">
                <i class="bx bx-envelope"></i>
                <a :href="'mailto:' + email.toLowerCase()" rel="nofollow noopener noreferrer" target="_blank" class="social__link">
                  {{ email }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="footer__bottom">
          <div class="relations">
            <p class="footer__rights">
              &copy; {{ currentYear() }} {{ $config.appName }}.
              <span>
                {{ $t('common.rights') }}
              </span>
            </p>
          </div>
          <div class="footer__app">
            <div class="bg-AppStore"></div>
            <div class="bg-GooglePlay"></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AccountFooterComponent',
  computed: {
    ...mapGetters('seos', [
      'contacts'
    ]),
    emails() {
      return this.contacts !== null && this.contacts.email !== null && this.contacts.email.length > 0 ? this.contacts.email.split(',') : null
    },
    contactsData() {
      return this.contacts !== null && this.contacts.length !== 0
        ? this.contacts
        : {
            description: '',
            email: '',
            phone: '',
            youtube: '',
            facebook: '',
            instagram: '',
            telegram: '',
            vk: '',
            twitter: ''
          }
    }
  },
  methods: {
    currentYear() {
      const date = new Date()
      return date.getFullYear()
    }
  }
}
</script>

<style scoped>
  @import url('~/assets/css/account/common/footer.css');
</style>
