<template>
  <div ref="modalArea" :class="{'modal-area': true, 'active': commonOpen}">
    <div ref="modalInner" class="modal">
      <div class="close-modal">
        <i class="bx bx-x" @click="closeModal"></i>
      </div>
      <div class="modal-content">
        <div v-if="operation === 'success'" class="modal-container">
          <div class="icon-area">
            <CheckAnimdated />
          </div>
          <div class="desc-area">
            <div v-if="successHeader.length > 0" class="header">
              {{ successHeader }}
            </div>
            <p class="desc">
              {{ successText }}
            </p>
          </div>
          <div class="button-area">
            <button type="button" :class="{'account-create__btn button': true, 'stroke-button': link !== null, 'filled-button': link === null}" @click="closeModal">
              {{ ctaButtonText }}
            </button>
            <button v-if="link !== null" type="button" class="account-create__btn button filled-button" @click.prevent="redirectToRoute">
              {{ link.title }}
            </button>
          </div>
        </div>
        <div v-if="operation === 'error'" class="modal-container">
          <div class="icon-area error">
            <i class="bx bx-error"></i>
          </div>
          <div class="desc-area">
            <div v-if="errorHeader.length > 0" class="header">
              {{ errorHeader }}
            </div>
            <p class="desc">
              {{ errorText }}
            </p>
          </div>
          <div class="button-area">
            <button type="button" :class="{'account-create__btn button': true, 'stroke-button': link !== null, 'filled-button': link === null}" @click="closeModal">
              {{ ctaButtonText }}
            </button>
            <button v-if="link !== null" type="button" class="account-create__btn button filled-button" @click.prevent="redirectToRoute">
              {{ link.title }}
            </button>
          </div>
        </div>
        <div v-if="operation === 'info'" class="modal-container">
          <div class="icon-area info">
            <i class="bx bx-info-circle"></i>
          </div>
          <div class="desc-area">
            <div v-if="infoHeader.length > 0" class="header">
              {{ infoHeader }}
            </div>
            <p class="desc">
              {{ infoText }}
            </p>
          </div>
          <div class="button-area">
            <button type="button" :class="{'account-create__btn button': true, 'stroke-button': link !== null, 'filled-button': link === null}" @click="closeModal">
              {{ ctaButtonText }}
            </button>
            <button v-if="link !== null" type="button" class="account-create__btn button filled-button" @click.prevent="redirectToRoute">
              {{ link.title }}
            </button>
          </div>
        </div>
        <div v-if="operation === 'loading'" class="modal-container">
          <div class="icon-area info">
            <Loader />
          </div>
          <div class="desc-area">
            <div v-if="loadingHeader.length > 0" class="header">
              {{ loadingHeader }}
            </div>
            <p class="desc">
              {{ loadingText }}
            </p>
          </div>
          <div class="button-area">
            <button type="button" class="account-create__btn button filled-button" @click="closeModal">
              {{ ctaButtonText }}
            </button>
          </div>
        </div>
        <div v-if="operation === 'topup'" class="modal-container">
          <div class="icon-area">
            <CheckAnimdated />
          </div>
          <div class="desc-area">
            <div class="header">
              {{ $t('invest.purchase_success') }}
            </div>
            <div v-if="externalData !== null" class="buy_area">
              <div class="info_row">
                <div class="label">
                  {{ $t('invest.purchase_success_tx') }}
                </div>
                <div class="value">
                  #{{ externalData.id !== undefined ? externalData.id : '...' }}
                </div>
              </div>
              <div class="info_row">
                <div class="label">
                  {{ $t('invest.purchase_success_price') }}
                </div>
                <div class="value">
                  ${{ externalData.price !== undefined ? externalData.price : '...' }}
                </div>
              </div>
              <div class="info_row">
                <div class="label">
                  {{ $t('invest.purchase_success_total') }}
                </div>
                <div class="value">
                  {{ externalData.id !== undefined ? $priceFormat(externalData.amount, 0) : '...' }} CRT
                </div>
              </div>
            </div>
            <p v-else class="desc">
              {{ $t('invest.purchase_success_desc') }}
            </p>
            <div class="warning-area">
              <div class="icon-area">
                <i class="bx bx-error"></i>
              </div>
              <div class="text-area">
                <p class="desc">
                  <span class="bold">
                    {{ $t('invest.note_word') }}:
                  </span>
                  {{ $t('invest.note_text') }}
                </p>
              </div>
            </div>
          </div>
          <div class="button-area">
            <button type="button" class="account-create__btn button stroke-button" @click="closeModal">
              {{ ctaButtonText }}
            </button>
            <a href="#" class="account-create__btn button filled-button" rel="nofollow noopener noreferrer" targat="_blank">
              {{ $t('invest.get_saft') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Loader from '~/components/misc/Loader'
import CheckAnimdated from '~/components/misc/CheckAnimdated'

export default {
  name: 'ModalCommonComponent',
  components: {
    Loader,
    CheckAnimdated
  },
  props: {
    process: {
      type: String,
      default: () => 'loading'
    }
  },
  data() {
    return {
      operation: this.process,
      infoHeader: '',
      errorHeader: '',
      successHeader: '',
      loadingHeader: '',
      infoText: '',
      errorText: '',
      successText: '',
      loadingText: '',
      link: null,
      redirectLink: null,
      ctaButtonText: this.$t('common.close'),
      externalData: null
    }
  },
  computed: {
    ...mapGetters('modal', [
      'commonOpen'
    ]),
    currentOperation() {
      return this.getOperationTitle(this.operation)
    }
  },
  created() {
    this.$nuxt.$on('modalLoading', (text = null, header = '') => this.openModal('loading', text, header))
    this.$nuxt.$on('modalSuccess', (text = null, header = '', link = null) => this.openModal('success', text, header, link))
    this.$nuxt.$on('modalError', (text = null, header = '', link = null) => this.openModal('error', text, header, link))
    this.$nuxt.$on('modalInfo', (text = null, header = '', link = null) => this.openModal('info', text, header, link))
    this.$nuxt.$on('modalTopup', (investment = null) => this.openTopup(investment))
    this.$nuxt.$on('modalSetRedirect', link => this.setRedirectLink(link))
    this.$nuxt.$on('modalSetCta', ctaText => this.setCtaButtonText(ctaText))
  },
  mounted() {
    window.addEventListener('click', this.handleClickEvents)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClickEvents)
  },
  methods: {
    ...mapActions('modal', [
      'changeCommonState'
    ]),
    getOperationTitle(type) {
      if (type === null) {
        return ''
      }
      switch (type.toLowerCase()) {
        case 'error':
          return this.$t('common.error_occured')
        case 'success':
          return this.$t('common.succeed')
        case 'info':
          return this.$t('common.info_notice')
        case 'loading':
        default:
          return this.$t('common.loading')
      }
    },
    openModal(type = null, descText = '', headerText = '', link = null) {
      if (type !== null) {
        this.operation = type
      }
      if (link !== null) {
        this.link = link
      }
      this.changeCommonState({
        state: true
      })
      switch (type.toLowerCase()) {
        case 'success':
          this.successText = descText
          this.successHeader = headerText
          break
        case 'info':
          this.infoText = descText
          this.infoHeader = headerText
          break
        case 'error':
          this.errorText = descText
          this.errorHeader = headerText
          break
        case 'loading':
        default:
          this.loadingText = descText
          this.loadingHeader = headerText
      }
    },
    openTopup(investment = null) {
      this.operation = 'topup'
      this.changeCommonState({
        state: true
      })
      this.externalData = investment
    },
    async redirectToRoute() {
      if (this.link !== null && this.$isValidUrl(this.link.route)) {
        window.open(this.link.route, '_blank')
      } else if (this.link !== null) {
        await this.$router.push({
          path: this.localePath(this.link.route)
        })
      }
      this.closeModal()
    },
    handleClickEvents(evt) {
      if (
        this.$refs.modalArea !== undefined &&
            this.$refs.modalArea.contains(evt.target) &&
            !this.$refs.modalInner.contains(evt.target)
      ) {
        this.closeModal()
      }
    },
    setRedirectLink(address) {
      this.redirectLink = address !== undefined && address !== null ? address : null
    },
    setCtaButtonText(newText = null) {
      this.ctaButtonText = newText !== undefined && newText !== null ? newText : this.$t('common.close')
    },
    async closeModal() {
      if (
        this.redirectLink !== null &&
        this.redirectLink.length > 0 &&
        (this.operation === 'success' || this.operation === 'info' || this.operation === 'warning')
      ) {
        const linkAddress = this.redirectLink
        this.redirectLink = null
        if (linkAddress !== null && this.$isValidUrl(linkAddress)) {
          window.open(this.link, '_blank')
        } else if (linkAddress !== null) {
          await this.$router.push({
            path: this.localePath(linkAddress)
          })
        }
      }
      this.clearMessages()
      this.setCtaButtonText()
      this.link = null
      await this.changeCommonState({
        state: false
      })
    },
    clearMessages() {
      this.infoHeader = ''
      this.infoText = ''
      this.successHeader = ''
      this.successText = ''
      this.errorHeader = ''
      this.errorText = ''
      this.loadingHeader = ''
      this.loadingText = ''
    }
  }
}
</script>

<style scoped>
    @import url('~/assets/css/components/modal.css');
</style>
