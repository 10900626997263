<template>
  <div class="spinning">
    <svg class="circular" viewBox="25 25 50 50">
      <circle
        class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="5"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SpinningLoader'
}
</script>

  <style scoped>
  .spinning {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  .spinning:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  .circular {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @-webkit-keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @-webkit-keyframes color {
    100%, 0% {
      stroke: #89acf0;
    }
    40% {
      stroke: #74a0f4;
    }
    66% {
      stroke: #DB9FFF;
    }
    80%, 90% {
      stroke: #698DE4;
    }
  }
  @keyframes color {
    100%, 0% {
      stroke: #89acf0;
    }
    40% {
      stroke: #74a0f4;
    }
    66% {
      stroke: #DB9FFF;
    }
    80%, 90% {
      stroke: #698DE4;
    }
  }
  </style>
