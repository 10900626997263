var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "modalArea", class: { "modal-area": true, active: _vm.commonOpen } },
    [
      _c("div", { ref: "modalInner", staticClass: "modal" }, [
        _c("div", { staticClass: "close-modal" }, [
          _c("i", { staticClass: "bx bx-x", on: { click: _vm.closeModal } }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _vm.operation === "success"
            ? _c("div", { staticClass: "modal-container" }, [
                _c(
                  "div",
                  { staticClass: "icon-area" },
                  [_c("CheckAnimdated")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "desc-area" }, [
                  _vm.successHeader.length > 0
                    ? _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.successHeader) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.successText) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      class: {
                        "account-create__btn button": true,
                        "stroke-button": _vm.link !== null,
                        "filled-button": _vm.link === null,
                      },
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ctaButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.link !== null
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "account-create__btn button filled-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.redirectToRoute.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.link.title) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.operation === "error"
            ? _c("div", { staticClass: "modal-container" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "desc-area" }, [
                  _vm.errorHeader.length > 0
                    ? _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errorHeader) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.errorText) + "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      class: {
                        "account-create__btn button": true,
                        "stroke-button": _vm.link !== null,
                        "filled-button": _vm.link === null,
                      },
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ctaButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.link !== null
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "account-create__btn button filled-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.redirectToRoute.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.link.title) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.operation === "info"
            ? _c("div", { staticClass: "modal-container" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "desc-area" }, [
                  _vm.infoHeader.length > 0
                    ? _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.infoHeader) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "\n            " + _vm._s(_vm.infoText) + "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      class: {
                        "account-create__btn button": true,
                        "stroke-button": _vm.link !== null,
                        "filled-button": _vm.link === null,
                      },
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ctaButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.link !== null
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "account-create__btn button filled-button",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.redirectToRoute.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.link.title) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.operation === "loading"
            ? _c("div", { staticClass: "modal-container" }, [
                _c("div", { staticClass: "icon-area info" }, [_c("Loader")], 1),
                _vm._v(" "),
                _c("div", { staticClass: "desc-area" }, [
                  _vm.loadingHeader.length > 0
                    ? _c("div", { staticClass: "header" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.loadingHeader) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.loadingText) +
                        "\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      staticClass: "account-create__btn button filled-button",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ctaButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.operation === "topup"
            ? _c("div", { staticClass: "modal-container" }, [
                _c(
                  "div",
                  { staticClass: "icon-area" },
                  [_c("CheckAnimdated")],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "desc-area" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("invest.purchase_success")) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.externalData !== null
                    ? _c("div", { staticClass: "buy_area" }, [
                        _c("div", { staticClass: "info_row" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.$t("invest.purchase_success_tx")) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                #" +
                                _vm._s(
                                  _vm.externalData.id !== undefined
                                    ? _vm.externalData.id
                                    : "..."
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "info_row" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("invest.purchase_success_price")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                $" +
                                _vm._s(
                                  _vm.externalData.price !== undefined
                                    ? _vm.externalData.price
                                    : "..."
                                ) +
                                "\n              "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "info_row" }, [
                          _c("div", { staticClass: "label" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$t("invest.purchase_success_total")
                                ) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "value" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.externalData.id !== undefined
                                    ? _vm.$priceFormat(
                                        _vm.externalData.amount,
                                        0
                                      )
                                    : "..."
                                ) +
                                " CRT\n              "
                            ),
                          ]),
                        ]),
                      ])
                    : _c("p", { staticClass: "desc" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("invest.purchase_success_desc")) +
                            "\n          "
                        ),
                      ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "warning-area" }, [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-area" }, [
                      _c("p", { staticClass: "desc" }, [
                        _c("span", { staticClass: "bold" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.$t("invest.note_word")) +
                              ":\n                "
                          ),
                        ]),
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("invest.note_text")) +
                            "\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "button-area" }, [
                  _c(
                    "button",
                    {
                      staticClass: "account-create__btn button stroke-button",
                      attrs: { type: "button" },
                      on: { click: _vm.closeModal },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.ctaButtonText) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "account-create__btn button filled-button",
                      attrs: {
                        href: "#",
                        rel: "nofollow noopener noreferrer",
                        targat: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("invest.get_saft")) +
                          "\n          "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-area error" }, [
      _c("i", { staticClass: "bx bx-error" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-area info" }, [
      _c("i", { staticClass: "bx bx-info-circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon-area" }, [
      _c("i", { staticClass: "bx bx-error" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }