var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "spinning" }, [
    _c("svg", { staticClass: "circular", attrs: { viewBox: "25 25 50 50" } }, [
      _c("circle", {
        staticClass: "path",
        attrs: {
          cx: "50",
          cy: "50",
          r: "20",
          fill: "none",
          "stroke-width": "5",
          "stroke-miterlimit": "10",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }