var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "check-area" }, [
    _c(
      "svg",
      {
        staticClass: "checkmark",
        attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 52 52" },
      },
      [
        _c("circle", {
          staticClass: "checkmark__circle",
          attrs: { cx: "26", cy: "26", r: "25", fill: "none" },
        }),
        _vm._v(" "),
        _c("path", {
          staticClass: "checkmark__check",
          attrs: { fill: "none", d: "M14.1 27.2l7.1 7.2 16.7-16.8" },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }